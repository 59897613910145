import { useSession, signIn } from "next-auth/react"
import {
  Typography,
  Space,
  Button,
  Input,
  Form,
} from 'antd';

import styles from '../styles/Home.module.css';

import { useRouter } from 'next/router';
import { useState } from "react";

const { Title } = Typography;

export default function Home() {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { data: session } = useSession();
  const [form] = Form.useForm();

  if (session) {
    router.push('/');
  };

  const onFinish = ({ email }: { email: string }) => {
    setLoading(true);
    signIn("email", { email });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

    return (
      <div className={styles.container}>
        <main className={styles.main}>
          <Space size="middle" direction="vertical" align="center">
            <Title>
              Locale editor
            </Title>
            <Form
              name="login"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input
                  placeholder="Email"
                  autoComplete="email"
                  type="email"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className={styles.loginButton} loading={loading}>
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </main>
      </div>
    );
}
